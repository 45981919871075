<template>
  <div class="gateway sub-pages">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />
    <div class="sub-pages-banner">
      <div class="img-box">
        <img :src="bannerUrl" alt="" />
      </div>
    </div>

    <div class="main-content gateway-module">
      <div class="module-title" v-show="pageTitle">{{ pageTitle }}</div>
      <div class="you-position" v-if="![].includes($route.query.type)">
        <i><img src="./modules/adress.png" alt="" /></i>
        <p>
          您的位置:产业园服务>公共服务中心{{
            `${firstNavName ? '>' + firstNavName : ''}`
          }}{{ lastNavName }}
        </p>
        <span @click="gobackpage">
          返回上级
          <img src="../../assets/img/gateway/icon-arrow-gray.png" alt="" />
          <img
            class="img-active"
            src="../../assets/img/gateway/icon-arrow-active.png"
            alt=""
          />
        </span>
      </div>
      <div class="module-content" v-loading="dataLoading">
        <!-- 百度地图区域 -->
        <div class="map-content" v-if="authString.includes('map')">
          <div id="map-canvas"></div>
          <div class="window-wrap">
            <div class="wrap-top">
              <!-- <img
                class="logo"
                src="../../assets/icon/el-icon-search.png"
                alt=""
              /> -->
              <span>{{ chartsData.name }}</span>
            </div>
            <div class="text-wrap" v-if="chartsData.point">
              <p>经度: {{ chartsData.point.lng }}</p>
              <p>纬度: {{ chartsData.point.lat }}</p>
            </div>
          </div>
        </div>

        <!-- 搜索框区域 -->
        <el-form
          :inline="true"
          :model="params"
          class="content-form-inline"
          v-if="authString.includes('search')"
        >
          <el-form-item v-if="[5, 6].includes(queryId)" label="日期">
            <el-date-picker
              v-model.trim="params.picker"
              type="date"
              placeholder="选择日期"
              :picker-options="datePickerAttributes.pickerOptions"
              @change="datePickerAttributes.change"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item v-if="[5, 6].includes(queryId)" label="片区">
            <el-select v-model="params.date" placeholder="请选择" clearable>
              <el-option
                v-for="(ele, idx) in dates"
                :key="idx"
                :label="ele.name"
                :value="ele.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="[3].includes(queryId)" label="地区">
            <el-select v-model="params.date" placeholder="请选择" clearable>
              <el-option
                v-for="(ele, idx) in dates"
                :key="idx"
                :label="ele"
                :value="ele"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="[1, 2].includes(queryId)" label="年份">
            <el-select v-model="params.date" placeholder="请选择" clearable>
              <el-option
                v-for="(ele, idx) in dates"
                :key="idx"
                :label="ele + '年'"
                :value="ele"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="[2].includes(queryId)" label="分类">
            <el-select
              v-model="params.modelType"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(ele, idx) in modelTypes"
                :key="idx"
                :label="ele.name"
                :value="ele.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="[1, 10].includes(queryId)"
            :label="queryId === 10 ? '企业名称' : '品种名称'"
          >
            <el-input
              v-model="params.searchWord"
              :placeholder="queryId === 10 ? '企业名称' : '品种名称'"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData(true)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>

        <!-- 表格模板 -->
        <div class="table-content-wrap" v-if="authString.includes('table')">
          <el-table :data="listData">
            <el-table-column
              v-for="(column, i) in tableColumn"
              :key="i"
              show-overflow-tooltip
              :prop="column.prop"
              :label="column.label"
            >
              <template slot-scope="scope">
                <div v-if="column.scope" v-html="column.scope(scope)"></div>
                <div v-else>{{ scope.row[column.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 图片布局样式 -->
        <div class="rows-img-content" v-if="authString.includes('rowsImg')">
          <ImgDetailLayout :dataList="listData" />
        </div>

        <!-- 渔业在线学习 -->
        <div class="online-learn" v-if="authString.includes('onlineLearn')">
          <ColumnNewLayout
            :dataList="listData"
            @clickChildItem="playVideo"
            type="video"
          />

          <div
            class="more-txt"
            @click="loadMore"
            v-if="params.totalPage > params.page"
          >
            查看更多<i></i>
          </div>
        </div>

        <!-- 页码 -->
        <el-pagination
          v-if="authString.includes('pagination')"
          :current-page="params.page"
          :page-sizes="authString.includes('table') ? [20, 30, 40] : [9]"
          :page-size="params.pageSize"
          :total="params.totalCount"
          layout="total, sizes, prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>

        <!-- 学术会议 列表 -->
        <div class="meeting-ls" v-if="$route.query.type == 2 && !isSecondLevel">
          <div class="ls-wrap">
            <li
              :class="['ls-item', { active: ele === 1 }]"
              v-for="(ele, idx) in listData"
              :key="idx"
              @click="toDetail(ele, 'meeting')"
            >
              <th>《{{ ele.title }}》</th>
              <span>「主办单位」:{{ ele.organizer }}</span>
              <span>「会场地点」:{{ ele.meetingPlace }}</span>
              <span>「开幕日期」:{{ ele.openingDate }}</span>
              <i>
                查看详情
                <img
                  src="../../assets/img/gateway/icon-arrow-gray.png"
                  alt="" />
                <img
                  class="img-active"
                  src="../../assets/img/gateway/icon-arrow-active.png"
                  alt=""
              /></i>
            </li>
          </div>
          <div
            class="more-txt"
            @click="loadMore"
            v-if="params.totalPage > params.page"
          >
            查看更多<i></i>
          </div>
        </div>

        <!-- 学术会议 详情 -->
        <div
          class="meeting-detail"
          v-if="$route.query.type == 2 && isSecondLevel"
        >
          <p>
            <span>「开幕日期」</span><i>{{ secondLevelData.openingDate }}</i>
          </p>
          <p>
            <span>「闭幕日期」</span><i>{{ secondLevelData.closingDate }}</i>
          </p>
          <p>
            <span>「会议城市」</span><i>{{ secondLevelData.meetingCity }}</i>
          </p>
          <p>
            <span>「会场地点」</span><i>{{ secondLevelData.meetingPlace }}</i>
          </p>
          <p>
            <span>「主办单位」</span><i>{{ secondLevelData.organizer }}</i>
          </p>
          <p>
            <span>「联系人」</span><i>{{ secondLevelData.contactPerson }}</i>
          </p>
          <p>
            <span>「联系电话」</span><i>{{ secondLevelData.contactNumber }}</i>
          </p>
          <p>
            <span>「邮箱」</span><i>{{ secondLevelData.contactEmail }}</i>
          </p>
          <p>
            <span>「会议简介」</span>
            <i v-html="secondLevelData.content"></i>
          </p>
        </div>

        <!-- 人物简介 -->
        <div
          class="personage-introduction"
          v-if="$route.query.type == 3 && !isSecondLevel"
        >
          <div class="person-info">
            <div class="img">
              <img :src="detailData.url" alt="" />
            </div>
            <div class="detail">
              <span>{{ detailData.name }}</span>
              <i>「职位」 {{ detailData.position }}</i>
              <p>
                「简介」
                {{ detailData.content }}
              </p>
            </div>
          </div>
          <div class="info-detail">
            <div class="child-nav">
              <li
                :class="['ls-item', { active: idx === personActIdx }]"
                v-for="(ele, idx) in ['详细介绍', '学术成果']"
                :key="idx"
                @click="personActIdx = idx"
              >
                {{ ele }}
              </li>
            </div>
            <div class="detail-content" v-show="personActIdx == 0">
              <article>
                <span>「人物简介」</span>
                <section>
                  {{ detailData.content }}
                </section>
              </article>
              <article>
                <span>「所在机构」</span>
                <section>{{ detailData.company }}</section>
              </article>
              <article>
                <span>「职位」</span>
                <section>{{ detailData.position }}</section>
              </article>
              <article>
                <span>「研究领域」</span>
                <section>
                  {{ detailData.researchAreas }}
                </section>
              </article>
            </div>
            <div class="academic-achievements" v-show="personActIdx == 1">
              <template
                v-if="
                  detailData.academicAchievementsList &&
                  detailData.academicAchievementsList.length
                "
              >
                <article
                  style="cursor: pointer"
                  v-for="(ele, idx) in detailData.academicAchievementsList"
                  :key="idx"
                  @click="toDetail(ele, 'achievements')"
                >
                  <span>{{ idx + 1 }}.[期刊] {{ ele.title }}</span>
                  <span>[作者] {{ ele.author }}</span>
                  <span>[来源] {{ ele.source }}</span>
                  <span>[摘要]<i v-html="ele.summary"></i></span>
                  <!-- <span v-html="'[摘要]' + ele.content"></span> -->
                  <hr />
                </article>
              </template>
            </div>
          </div>
        </div>

        <!-- 学术成果详情 详情 -->
        <div
          class="meeting-detail"
          v-if="$route.query.type == 3 && isSecondLevel"
        >
          <p>
            <span>「作者」</span><i>{{ secondLevelData.author }}</i>
          </p>
          <p>
            <span>「发表年度」</span
            ><i>{{
              $.formatDate(new Date(secondLevelData.publicationDate), 'yyyy')
            }}</i>
          </p>
          <p>
            <span>「发表月」</span
            ><i>{{
              $.formatDate(new Date(secondLevelData.publicationDate), 'MM')
            }}</i>
          </p>
          <p>
            <span>「期刊名称」</span><i>{{ secondLevelData.journalTitle }}</i>
          </p>
          <p>
            <span>「卷」</span><i>{{ secondLevelData.roll }}</i>
          </p>
          <p>
            <span>「期」</span><i>{{ secondLevelData.phase }}</i>
          </p>
          <p>
            <span>「页码范围」</span><i>{{ secondLevelData.pageRange }}</i>
          </p>
          <p>
            <span>「发表日期」</span
            ><i>{{
              secondLevelData.publicationDate &&
              secondLevelData.publicationDate.split(' ')[0]
            }}</i>
          </p>
          <p>
            <span>「正文」</span>
            <i v-html="secondLevelData.content"></i>
          </p>
        </div>

        <!-- 人物简介 -->
        <div class="personage-introduction" v-if="$route.query.type == 4">
          <div class="info-detail">
            <div class="detail-content">
              <article>
                <span>「作者」: {{ detailData.author }}</span>
              </article>
              <article>
                <span>「摘要」</span>
                <section>{{ detailData.remark }}</section>
              </article>
              <article>
                <span>「详细介绍」</span>
                <section v-html="detailData.content"></section>
              </article>
            </div>
          </div>
        </div>

        <!-- <iframe
          id="fff"
          style="width: 100%; height: 600px"
          src="//fishery.ckcest.cn/thesaurus/index.html"
          frameborder="0"
        ></iframe> -->
        <!-- 详情 -->
        <div class="item-detail" v-if="['5'].includes($route.query.type)">
          <div v-html="detailData.content"></div>
        </div>
      </div>
    </div>
    <ToTopperComponent />
    <gateway-footer />

    <el-dialog
      class="video-dialog"
      title="渔业在线学习"
      :width="app.fontHandle(1200, 'px')"
      height="60vh"
      top="10vh"
      :visible.sync="dialogVRVisible"
    >
      <video
        v-if="dialogVRVisible"
        class="onlinePlayer"
        muted
        autoplay
        controls
        width="100%"
        height="100%"
        preload="false"
        :src="detailData.url || detailData.urlKey"
      ></video>
    </el-dialog>
  </div>
</template>
<script>
import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ImgDetailLayout from '../../components/img-detail-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import ColumnNewLayout from '../../components/column-new-layout.vue'

import { methods } from '../zhanyu/modules/methods'
import { loadBMap } from '../../utils/baidu-map/loadResources'
import * as _static from './modules/static'
import { utils as _utils } from './modules/public-utils'

import Vue from 'vue'
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
  DatePicker,
} from 'element-ui'
Vue.use(Form)
  .use(FormItem)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Table)
  .use(TableColumn)
  .use(Pagination)
  .use(DatePicker)

export default {
  name: 'sub-pages',
  data() {
    return {
      bannerUrl: '',
      dialogVRVisible: false,
      // 插件设置
      datePickerAttributes: {
        // 日期
        // editable: true, // 文本框可输入
        // pickerOptions: {
        //   disabledDate (time) {
        //     return time.getTime() < Date.now() - 8.64e7
        //   }
        // },
        change(data) {
          // console.log('data:', data)
          return data
        },
      },
      app: {},
      queryId: 0,
      queryType: 0,
      // 表格位置表头
      tableColumn: [],
      detailData: {}, // 当前页面内的数据
      listData: [], // 各种列表据
      personActIdx: 0, // 人物介绍内nav
      isSecondLevel: false,
      secondLevelData: {},
      authString: '', // 显示模块
      pageTitle: '', // 中间大标题
      firstNavName: '', // 导航栏第一个 自负床
      lastNavName: '', // 最后一级的 位置自负床
      dataLoading: true,
      dates: [],
      modelTypes: [], // 分类数组
      // 分页数据
      params: {
        picker: new Date(),
        searchWord: '',
        pageSize: 20,
        page: 1,
        totalCount: 0,
        date: '',
        modelType: '',
      },
      listData: [], // 当前页面内的 列表数据
      twelve_area_overlays: [],
      citys_overlays: [],
      citys: [], // 企业数据
      chartsData: {},
    }
  },
  methods: {
    ...methods,
    ..._utils,
    playVideo(data) {
      this.detailData = data
      this.dialogVRVisible = true
    },
    toDetail(data, type) {
      console.log('toDetail', data, type)
      this.isSecondLevel = !this.isSecondLevel
      if (type === 'achievements') {
        this.lastNavName += '>学术成果详情'
        let url = `/zhanjiang-portal/frontAcademicCircles/getAcademicListDetails/${data.id}`
        this.getSecondDetail(url)
      }
      if (type === 'meeting') {
        this.lastNavName += '>会议详情'
        let url = `/zhanjiang-portal/frontFeaturedResources/getAcademicInformation/${data.id}`
        this.getSecondDetail(url)
      }
    },
    gobackpage() {
      if (this.isSecondLevel) {
        this.isSecondLevel = !this.isSecondLevel
        this.lastNavName = this.lastNavName.split('>')[0]

        return false
      }
      // this.$router.go(-1)
      this.$router.push(`/parkService?type=${this.$route.query.type}`)
    },
    getSecondDetail(url) {
      this.$axios({
        url: url,
        method: 'post',
      }).then((r) => {
        this.secondLevelData = r.data
      })
    },
    // 强自定义地图弹窗
    addInfoWindow(marker, data) {
      let map = this.map
      let that = this
      var infoWindow = new BMapGL.InfoWindow(
        document.querySelector('.window-wrap'),
        {
          width: 344,
          height: 158,
        }
      )
      marker.addEventListener('click', function () {
        that.chartsData = data
        document.querySelector('.window-wrap').style.opacity = '1'
        this.openInfoWindow(infoWindow)
        // 图片加载完毕重绘infoWindow
        // document.getElementById('imgDemo').onload = function () {
        //   // map.reset()
        //   infoWindow.redraw() // 防止在网速较慢时生成的信息框高度比图片总高度小，导致图片部分被隐藏
        // }
      })
    },
    fontHandle(num, unit) {
      return unit ? num + unit : num
    },
    // 地图初始化后的主方法 **
    mapHandle() {
      var map = new BMapGL.Map('map-canvas')
      this.map = map
      map.setMapStyleV2({
        styleId: '0b09a3c6ed2d04d268eee6cb8f58ada7',
      })
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放

      this.seaArea('subpages')

      this.customeControlClass()

      if (this.listData.length) {
        this.citys = this.listData.map((e) => e.value)
        this.haveHeightPoint(this.listData.map((e) => e.name))
      }
    },

    loadMapFunc() {
      window.initBaiduMapScript = () => {
        this.mapHandle()
      }
      loadBMap('initBaiduMapScript')
    },
    getListData(isInit) {
      // console.log('getListData')
      if (isInit) this.params.page = 1
      this.initialEnv()
    },
    loadMore() {
      this.params.page++
      this.getListData()
    },
    initDataRequest(isInit) {
      if (!isInit) return
      let queryId = this.queryId
      let getUrl = ''
      switch (queryId) {
        case 1:
          getUrl =
            '/zhanjiang-portal/frontFeaturedResources/queryBreedPriceYearList'
          break
        case 2:
          getUrl =
            '/zhanjiang-portal/frontFeaturedResources/queryYearListByYearBook'
          break
        case 3:
          getUrl = '/zhanjiang-portal/frontFeaturedResources/queryRegionList'
          break
        case 5:
          getUrl = '/zhanjiang-portal/frontFeaturedResources/queryBoxIdList'
          break
        case 6:
          getUrl = '/zhanjiang-portal/frontFeaturedResources/queryBoxIdList'
          break

        default:
          break
      }

      if ([2].includes(queryId)) {
        this.$axios(
          '/zhanjiang-portal/frontFeaturedResources/queryYearListByModelType'
        ).then((r) => (this.modelTypes = r.data))
      }

      getUrl &&
        this.$axios(getUrl).then((r) => {
          this.dates = r.data
          if ([5, 6].includes(queryId)) {
            this.params.date = r.data[0].id
            this.initialEnv()
          }
        })
    },
    // 计算出请求参数
    calcParams(curdata) {
      switch (curdata.auth) {
        case 'search,table,pagination':
          this.tableColumn = curdata.tableColumn
          return {
            callback: (r) => {
              delete r.data.list
              this.params = { ...this.params, ...r.data }
            },
            params: {
              ...this.params,
              param: {
                breedName: this.params.searchWord,
                companyName: this.params.searchWord,
                modelType: this.params.modelType,
                [[1, 2].includes(this.queryId)
                  ? 'operationYear'
                  : 'operationMonth']: this.params.date,
                region: this.params.date || '',
                boxId: this.params.date || '',
                timeEnd:
                  this.$.formatDate(this.params.picker, 'yyyy-MM-dd') || '',
                timeStart:
                  this.$.formatDate(this.params.picker, 'yyyy-MM-dd') || '',
              },
            },
            initData: this.initDataRequest,
          }

        case 'rowsImg,pagination':
          this.tableColumn = curdata.tableColumn
          this.params.pageSize = 9
          return {
            callback: (r) => {
              this.listData = r.data.list.map((ele) => ({
                name: this.queryId === 4 ? ele.seedlingName : ele.name,
                position:
                  this.queryId === 4
                    ? '「繁育单位」<br>&nbsp&nbsp' + ele.breedUnit
                    : '「产品机构」<br>&nbsp&nbsp' + ele.company,
                url: this.queryId === 4 ? ele.imageUrl : ele.url,
              }))
              delete r.data.list
              this.params = { ...this.params, ...r.data }
            },
            params: {
              ...this.params,
              param: {
                systematics: 2,
              },
            },
          }

        case 'map':
          return {
            callback: (r) => {
              if (this.map) {
                this.citys = r.data.map((e) => e.value)
                this.haveHeightPoint(r.data.map((e) => e.name))
              }
            },
          }

        case 'onlineLearn':
          this.params.pageSize = 15
          return {
            callback: (r) => {
              delete r.data.list
              this.params = { ...this.params, ...r.data }
            },
            params: {
              ...this.params,
            },
          }

        default:
          return {}
      }
    },
    // 初始话环境变量
    initialEnv(isInit) {
      let method = '' //请求方式
      let data = []
      let url = ''
      let callback = null
      let params = null
      let queryId = +this.$route.query.id
      let type = +this.$route.query.type
      this.queryType = type
      this.queryId = queryId
      let specialRequest = type === 1 && [5, 6].includes(queryId)
      // type: 产业园服务 nav 类型 1-5
      switch (type) {
        case 1:
          data = _static.SpecialResource[queryId - 1]
          this.pageTitle = data.title
          this.authString = data.auth
          this.lastNavName = this.pageTitle
          url = data.requestUrl
          let temp = this.calcParams(data)
          params = temp.params
          callback = temp.callback
          isInit && temp.initData && temp.initData(isInit)
          break

        case 2:
          data = _static.SpecialService[queryId - 1]
          this.pageTitle = data.title
          this.authString = data.auth
          this.lastNavName = this.pageTitle
          url = data.requestUrl
          this.params.pageSize = 8
          params = {
            ...this.params,
          }
          callback = (r) => {
            delete r.data.list
            this.params = { ...this.params, ...r.data }
          }
          break

        case 3:
          data = _static.SpecialResource[queryId - 1]
          this.lastNavName = this.pageTitle
          url = `/zhanjiang-portal/frontAcademicCircles/getAcademicDetails/${this.$route.query.id}`
          callback = this.break
          break
        case 4:
          data = _static.SpecialService[queryId - 1]
          this.lastNavName = this.pageTitle
          url = `/zhanjiang-portal/frontEncylopedia/getFisheriesDetails/${this.$route.query.id}`
          callback = (r) => {
            console.log(r)
            this.pageTitle = r.data.title
          }
          break

        case 5:
          data = _static.SpecialService[queryId - 1]
          this.lastNavName = '详情'
          url = `/zhanjiang-portal/frontIndustryPolicy/getIndustryPolicyDetails/${this.$route.query.id}`
          callback = (r) => {
            this.pageTitle = r.data.title
          }
          break
      }

      this.dataLoading = true

      // 排除
      if (specialRequest && isInit) return

      this.$axios({
        method: method || [8].includes(queryId) ? 'get' : 'post',
        url,
        data: { ...(params || {}) },
      }).then((r) => {
        this.detailData = r.data
        if (
          this.params.page > 1 &&
          (this.authString.includes('onlineLearn') ||
            (this.$route.query.type == 2 && !this.isSecondLevel))
        ) {
          this.listData = [...this.listData, ...r.data.list]
        } else {
          this.listData = r.data.list
        }

        if (this.authString.includes('onlineLearn')) {
          // this.listData.map((ele, idx) => {
          //   ele.url = `//agri-cloud.oss-cn-shenzhen.aliyuncs.com/zhanyu/video${
          //     idx || '-frist'
          //   }.mp4`
          // }) // 静态资源
        }
        console.log('listData', this.listData, this.tableColumn)
        callback && callback(r)

        setTimeout(() => {
          this.dataLoading = false
        }, 800)
      })
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    ImgDetailLayout,
    ColumnNewLayout,
    ToTopperComponent,
  },
  computed: {
    // 'search,table,pagination,rowsImg,map'
  },
  beforeMount() {
    // 模板编译/挂载之前
    this.firstNavName =
      this.$route.query.type &&
      _static.PublishNav[this.$route.query.type - 1].name
    this.initialEnv(true)
  },
  mounted() {
    // 模板编译/挂载之后
    this.authString.includes('map') && this.loadMapFunc()
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  created() {
    this.app.fontHandle = this.fontHandle
    this.$axios('/zhanjiang-portal/frontIndustrial/getBanner').then(
      (r) => (this.bannerUrl = r.data[0].url || '')
    )
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './sub-pages.less';
</style>

<template>
  <div class="gateway park-service">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="park-service-banner">
      <div class="img-box">
        <img :src="bannerUrl" alt="" />
      </div>
    </div>

    <div class="main-content gateway-module">
      <div class="module-content">
        <div class="sub-nav-box">
          <li
            :class="['ls-item', { active: idx === lsHoverIdx - 1 }]"
            v-for="(ele, idx) in navLs"
            :key="idx"
            @click="clickItem(idx)"
          >
            {{ ele.name }}
          </li>
        </div>

        <!-- 行业政策 -->
        <div class="news-box" v-if="lsHoverIdx === 5">
          <div class="search-box">
            <el-input
              placeholder="请输入标题"
              v-model="params.searchWord"
              @change.native="getListData"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="news-ls">
            <li
              :class="['ls-item', { active: ele === 1 }]"
              v-for="ele in listData"
              :key="ele.id"
              @click="clickSubItem(ele)"
            >
              <p>{{ ele.title }}</p>
              <i>{{ ele.createTime && ele.createTime.split(' ')[0] }}</i>
            </li>
          </div>
          <div
            class="more-txt"
            @click="loadMore"
            v-if="listData.length > params.pageSize * params.pageNum"
          >
            查看更多<i></i>
          </div>
        </div>

        <div class="fisheries-wiki" v-if="lsHoverIdx === 4">
          <div class="search-box">
            <el-input
              placeholder="请输入标题"
              v-model="params.searchWord"
              @change.native="getListData"
            >
              <!-- @keyup.enter.native="getListData" -->
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="wiki-ls">
            <div class="source-dir">
              <div class="name">养殖信息数据资源目录</div>
              <div class="dir-name">
                <i class="el-icon-caret-bottom"></i>类型
              </div>
              <div class="dir-ls">
                <el-checkbox-group
                  v-model="params.encyclopediaProperties"
                  @change="cahngedata"
                >
                  <el-checkbox
                    v-for="ele in dics"
                    :key="ele.id"
                    :label="ele.id"
                    >{{ ele.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="ls-wrap">
              <li
                :class="['ls-item', { active: ele === 1 }]"
                v-for="ele in listData"
                :key="ele.id"
                @click="clickSubItem(ele)"
              >
                <th>《{{ ele.title }}》</th>
                <span>「作者」:{{ ele.author }}</span>
                <p>「摘要」:{{ ele.remark }}</p>
                <i>查看详情</i>
              </li>
            </div>
          </div>
          <div
            class="more-txt"
            @click="loadMore"
            v-if="listData.length > params.pageSize * params.pageNum"
          >
            查看更多<i></i>
          </div>
        </div>

        <div class="academic-circles" v-if="lsHoverIdx === 3">
          <div class="search-box">
            <el-input
              placeholder="请输入学者姓名"
              v-model="params.searchWord"
              @change.native="getListData"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="academic-ls">
            <ImgDetailLayout
              :dataList="listData"
              @clickChildItem="clickSubItem"
            />
          </div>
        </div>

        <div class="special-services" v-if="lsHoverIdx === 2">
          <div class="services-ls">
            <ParkServiceLs
              :dataList="servicesls"
              @clickChildItem="clickSubItem"
            />
          </div>
        </div>

        <div class="special-resources" v-if="lsHoverIdx === 1">
          <div class="resources-ls">
            <ParkServiceLs
              :dataList="resourcesls"
              @clickChildItem="clickSubItem"
            />
          </div>
        </div>
      </div>
    </div>

    <gateway-footer style="background: #031236; color: #fff" />
    <ToTopperComponent />
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import Vue from 'vue'
import { Input, Checkbox, CheckboxGroup } from 'element-ui'
Vue.use(Input).use(Checkbox).use(CheckboxGroup)

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ParkServiceLs from '../../components/park-service-ls.vue'
import ImgDetailLayout from '../../components/img-detail-layout.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'

import { SpecialResource, SpecialService, PublishNav } from './modules/static'
import { utils as _utils } from './modules/public-utils'

export default {
  name: 'park-service',
  data() {
    return {
      bannerUrl: '',
      navLs: PublishNav,
      listData: [],
      servicesls: [],
      resourcesls: [],
      lsHoverIdx: 1,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000, //1秒切换一次
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      dics: [],
      params: {
        searchWord: '',
        pageSize: 10,
        pageNum: 1,
        total: 10,
        encyclopediaProperties: [],
      },
    }
  },
  computed: {},
  methods: {
    cahngedata(data) {
      console.log(data, this.params.encyclopediaProperties)
      localStorage.setItem('selectArr', JSON.stringify(data))
      this.initData()
    },
    clickItem(idx) {
      this.lsHoverIdx = idx + 1
      this.listData = []
      sessionStorage.setItem('firstNav', this.navLs[idx].name)
      this.params.searchWord = ''
      this.initData(true)
    },
    clickSubItem(data) {
      this.$router.push(`/subPages?id=${data.id || 0}&type=${this.lsHoverIdx}`)
    },
    ..._utils,
    getListData(r) {
      console.log(r)
      // 赋值
      if (r.code == '00000') {
        if (Array.isArray(r.data)) {
          this.listData = r.data
        }
        if (Array.isArray(r.data.list)) {
          this.listData = r.data.list
        }
      } else {
        // 搜索
        console.log('Enter按钮')
        this.initData()
      }
    },
    getDic() {
      return this.$axios(
        '/zhanjiang-portal/frontEncylopedia/dictionary/queryDictionaryList'
      ).then((r) => {
        this.dics = r.data
        let encyclopediaProperties =
          JSON.parse(localStorage.getItem('selectArr')) ||
          this.dics.map((e) => e.id)
        this.params.encyclopediaProperties = encyclopediaProperties
      })
    },
    async initData(isInit) {
      if (this.lsHoverIdx < 3) return
      let url = ''
      let callback = null
      switch (this.lsHoverIdx) {
        case 3:
          url = '/zhanjiang-portal/frontAcademicCircles/getAcademicCircles'
          callback = this.getListData
          break

        case 4:
          isInit && (await this.getDic())
          url = '/zhanjiang-portal/frontEncylopedia/getFisheriesEncyclopedia'
          callback = this.getListData
          break

        case 5:
          url = '/zhanjiang-portal/frontIndustryPolicy/queryPage'
          callback = this.getListData
          break
      }

      this.$axios({
        method: 'post',
        url,
        data: {
          pageSize: 100,
          param: {
            name: this.params.searchWord,
            title: this.params.searchWord,
            encyclopediaProperties: this.params.encyclopediaProperties,
          },
          page: 1,
        },
      }).then((r) => {
        callback && callback(r)
      })
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    ToTopperComponent,
    swiper,
    swiperSlide,
    ParkServiceLs,
    ImgDetailLayout,
  },
  created() {
    this.lsHoverIdx = +this.$route.query.type || 1
    this.resourcesls = []
    let typeName = ''
    SpecialResource.forEach((ele, idx) => {
      if (ele.parName !== typeName) {
        this.resourcesls.push({ title: ele.parName, child: [] })
        typeName = ele.parName
      }

      this.resourcesls[this.resourcesls.length - 1].child.push(ele)
    })

    this.servicesls = []
    SpecialService.forEach((ele, idx) => {
      if (ele.parName !== typeName) {
        this.servicesls.push({ title: ele.parName, child: [] })
        typeName = ele.parName
      }

      this.servicesls[this.servicesls.length - 1].child.push(ele)
    })

    this.$axios('/zhanjiang-portal/frontIndustrial/getBanner').then(
      (r) => (this.bannerUrl = r.data[0].url || '')
    )

    this.initData(true)
  },
  mounted() {
    // 模板编译/挂载之后
  },

  beforeRouteEnter: (to, from, next) => {
    // console.log('beforeRouteEnter')
    const specialRoutes = ['subPages']
    if (!specialRoutes.includes(from.name)) {
      to.meta.savedPosition = 0
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    // console.log('beforeRouteLeave')
    from.meta.savedPosition = document.querySelector('#app').scrollTop
    next()
  },
}
</script>
<style lang="less" scoped>
@import './park-service.less';
</style>

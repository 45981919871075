/*
 * @Descripttion: 项目中心
 * @Author: chenjie
 * @Date: 2021-10-28 15:14:15
 * @LastEditors: chenjie
 * @LastEditTime: 2021-10-28 17:09:02
 */

import axios from '@/axios'

/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取项目信息中心banner
 * @params {*}
 * @param {*} type
 */
 export function getBanner(type){
  return  axios({
    url:'/zhanjiang-portal/frontIndustrial/getBanner',
    method:'get'
  })
}
/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 显示产业园服务信息门户项目信息中心
 * @params {*}
 * @param {*} type
 */
 export function getInformationCenter(data){
  return  axios({
    url: '/zhanjiang-portal/frontIndustrial/getInformationCenter',
    method: 'post',
    data: data
  })
}
/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 产业园服务信息门户项目信息中心详情
 * @params {*}
 * @param {*} type
 */
 export function getInformationCenterDetail(params){
  return  axios({
    url:'/zhanjiang-portal/frontIndustrial/getInformationCenter/' + params,
    method:'post'
  })
}
// 特色资源
export const SpecialResource = [
  {
    title: '湛江水产品批发市场价格信息',
    id: 1,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryBreedPricePage',
    parName: '渔业统计数据',
    iconUrl: require('../../../assets/img/gateway/icon-group1.png'),
    auth: 'search,table,pagination',
    tableColumn: [
      {
        prop: "breedName",
        label: "品种名称"
      },
      {
        prop: "txt",
        label: "价格来源",
        scope: (e) => '湛江水产批发市场'
      },
      {
        prop: "breedPrice",
        label: "售卖价格（元）"
      },
      {
        prop: "operationYear",
        label: "日期",
        scope: (e) => `${e.row.operationYear}年${e.row.operationMonth}月`
      },
    ],
    describe:
      '收集湛江市水产批发市场价格数据，包含品种、规格、市场名称，时间长度等',
  },
  {
    title: '湛江渔业统计年鉴',
    id: 2,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryYearBookPage',
    parName: '渔业统计数据',
    iconUrl: require('../../../assets/img/gateway/icon-group2.png'),
    auth: 'search,table,pagination',
    tableColumn: [
      {
        prop: "operationYear",
        label: "年份"
      },
      {
        prop: "modelType",
        label: "分类"
      },
      {
        prop: "productionValue",
        label: "产值（亿元）"
      },
      {
        prop: "productionCapacity",
        label: "产能（万吨）"
      },
    ],
    describe:
      '收集湛江市渔业生产统计数据，包含养殖、苗种、生产、加工、物流贸易等',
  },
  {
    title: '湛江渔业贸易数据',
    id: 3,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryLogisticsTradeByPage',
    parName: '渔业统计数据',
    iconUrl: require('../../../assets/img/gateway/icon-group3.png'),
    auth: 'search,table,pagination',
    tableColumn: [
      {
        prop: "region",
        label: "地区"
      },
      {
        prop: "input",
        label: "进口（亿元）"
      },
      {
        prop: "outPut",
        label: "出口（亿元）"
      },
    ],
    describe: '收集湛江市水产品进出口贸易数据',
  },
  {
    title: '湛江海水苗种资源库',
    id: 4,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/getSeawaterSeedInformation',
    parName: '联合服务平台',
    iconUrl: require('../../../assets/img/gateway/icon-group4.png'),
    auth: 'rowsImg,pagination',
    describe: '收集湛江市养殖苗种数据',
  },
  {
    title: '湛江渔业水质监测',
    id: 5,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryWaterDataByPage',
    parName: '渔业资源环境',
    iconUrl: require('../../../assets/img/gateway/icon-group5.png'),
    auth: 'search,table,pagination',
    tableColumn: [
      {
        prop: "boxName",
        label: "区域"
      },
      {
        prop: "oxygen",
        label: "溶氧(mg/L)"
      },
      {
        prop: "ph",
        label: "ph值"
      },
      {
        prop: "uploadTime",
        label: "日期"
      },
    ],
    describe: '收集湛江市十二片区生态养殖水质数据',
  },
  {
    title: '湛江渔业温度监测',
    id: 6,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryWaterDataByPage',
    parName: '渔业资源环境',
    iconUrl: require('../../../assets/img/gateway/icon-group6.png'),
    auth: 'search,table,pagination',
    tableColumn: [
      {
        prop: "boxName",
        label: "区域"
      },
      {
        prop: "water",
        label: "水温 (℃)"
      },
      {
        prop: "uploadTime",
        label: "日期"
      },
    ],
    describe: '收集湛江市十二片区生态养殖水温数据',
  },
  {
    title: '湛江渔业气象监测',
    id: 7,
    bannerUrl: '',
    requestUrl: '',
    parName: '渔业资源环境',
    iconUrl: require('../../../assets/img/gateway/icon-group7.png'),
    auth: '',
    linkTo: 'http://data.cma.cn/dataGis/static/gridgis/#/pcindex',
    describe: '收集湛江市十二片区生态养殖气象数据',
  },
  {
    title: '湛江水产品追溯示范企业',
    id: 8,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryCompanyListByMap',
    parName: '渔业质量安全',
    iconUrl: require('../../../assets/img/gateway/icon-group8.png'),
    auth: 'map',
    describe:
      '收集湛江市水产品追溯系统建设示范企业数据，包含企业名称、地址',
  },
  {
    title: '湛江无公害渔业产品',
    id: 9,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/getPollutionFreeFishery',
    parName: '渔业质量安全',
    iconUrl: require('../../../assets/img/gateway/icon-group9.png'),
    auth: 'rowsImg,pagination',
    describe: '收集湛江市三品一标认证产品',
  },
  {
    title: '湛江渔业企业数据',
    id: 10,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/queryCompanyByPage',
    parName: '渔业质量安全',
    iconUrl: require('../../../assets/img/gateway/icon-group10.png'),
    auth: 'search,table,pagination',
    tableColumn: [
      {
        prop: "companyName",
        label: "企业名称"
      },
      {
        prop: "registerCapital",
        label: "注册资金"
      },
      {
        prop: "detailedAddress",
        label: "注册所在地"
      },
      {
        prop: "companyWebsite",
        label: "网址"
      },
      {
        prop: "contactPhone",
        label: "联系号码"
      },
      {
        prop: "contactEmail",
        label: "电子邮箱"
      },
    ],
    describe: '收集湛江市在渔业领域市值、占有率的涉渔企业',
  },
  {
    title: '渔业在线学习',
    id: 11,
    bannerUrl: '',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/getOnlineLearning',
    parName: '渔业培训服务',
    iconUrl: require('../../../assets/img/gateway/icon-group11.png'),
    auth: 'onlineLearn',
    describe: '收集关于渔业方面知识视频',
  },
]

// 特色服务
export const SpecialService = [
  {
    title: 'FAO统计数据Excel工具',
    id: 1,
    bannerUrl: '',
    parName: '线下工具',
    iconUrl: require('../../../assets/img/gateway/icon-serve1.png'),
    auth: '',
    linkTo: 'http://fishery.ckcest.cn/serviceFaodetalis.html',
    describe:
      '该工具是一个由粮农组织开发的用户友好工具，用以促进对水产养殖部门业绩的评估和监测。',
  },
  {
    title: '区域水产养殖适应性评价工具',
    id: 2,
    bannerUrl: '',
    parName: '线下工具',
    iconUrl: require('../../../assets/img/gateway/icon-serve2.png'),
    auth: '',
    linkTo: 'http://fishery.ckcest.cn/regionalAquaculture.html',
    describe:
      '借助GIS技术，使用多准则分析方法，实现对影响某区域内水产养殖的各种因素数据处理。',
  },
  {
    title: '学术会议信息服务平台',
    id: 3,
    bannerUrl: '',
    parName: '线上应用',
    requestUrl: '/zhanjiang-portal/frontFeaturedResources/getAcademicConferenceInformation',
    iconUrl: require('../../../assets/img/gateway/icon-serve3.png'),
    auth: 'none',
    describe:
      '主要发布涉渔行业会议、学术会议、讲座等',
  },
  {
    title: '词表查询',
    id: 4,
    bannerUrl: '',
    parName: '线上应用',
    iconUrl: require('../../../assets/img/gateway/icon-serve4.png'),
    auth: '',
    linkTo: 'http://fishery.ckcest.cn/thesaurus/index.html',
    describe:
      '主要是帮助非渔业领域的用户熟悉渔业领域的知识体系，加深渔业领域的用户对渔业领域知识组织的理解',
  },
]

// 公共服务nav
export const PublishNav = [
  { name: '特色资源' },
  { name: '特色服务' },
  { name: '学术圈' },
  { name: '渔业百科' },
  { name: '行业政策' },
]

// 公共服务nav
export const ExpertNav = [
  { name: '专家服务' },
  { name: '提问' },
  { name: '我的提问' },
]
